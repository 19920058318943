#modal{
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    width: 70%;
    height: 70%;
    margin: auto;   
    border: #617D8B solid 15px;
    border-radius: 4px;

}

#addStudentModalContainer {
    height: 100%;
    display: flex;
    width: 100%;
    flex-direction: column;
    background-color: aliceblue;
    padding: 0;
    text-align: center;
    overflow-x: hidden;
}

#addStudentModalContainer h2 {
    margin-top: 5px;
    color: #617D8B;
}
.addStudentModel {
    background-color: aliceblue;
    width: 100%;
}

#form {
    background-color: aliceblue;
}

.addStudentDDMenu {
    width: 200px;
}
/* .editStudentModal {
    display: flex;
    flex-direction: column;
    height: 40%;
}

.editStudentModal  {
    height: 40%;
} */

.labelContainer {
    display: flex;
    flex-direction: column;
}

.labelContainer label {
    transform: none;
}

.labelContainer  .labelSelection{ 
    margin: 0;
    text-align: left;
}

#addStudent {
    margin: 10px;
}

#editStudent {
    margin: 10px;
}

.formImgContainer {
    display: flex;
    flex-direction: column;
    align-content: center;
    justify-content: center;
}