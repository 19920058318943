.sponsorChildContainer {
    position: relative;
    display: flex;
    flex-wrap: wrap
}
.paymentContainer {
    position: fixed;
    margin-top: 2%;
    margin-left: 5%;
    width: 34%;
}
.studentCardContainer {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    flex-wrap: wrap;
    width: 60%;
    border: black;
    justify-content: center;
}

.studentCard {
    width: 250px;
    margin: 10px;
}

.totalDueLabel {
    display: inline;
    color: black;
}
