body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.loginContainer {
    padding: 30px;
    max-width: 50%;
    margin: 30px auto 0 auto;
}

.loginContainer button:not(:last-child) {
    margin-bottom: 10px;
}

.btnContainer {
    max-width: 65%;
    margin: 0 auto;
    margin-top: 10px;
}

.setUp {
    color: gray;
    text-align: center;
}
.setUp a {
    color: blue;
    cursor: pointer;
}
.onboardingContainer {
    margin: 0 auto;
    border: none;
    box-shadow: none;
}

.onboardingCell {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
}

.schoolFormContainer {
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 25px;
}

.schoolFormContainer h4 {
    text-transform: uppercase;
}
.accountTypeLabel {
    color: #617D8B;
    text-align: left;
    margin: 0;
    margin-top: -15px;
    padding-left: 35px;
    font-size: .87rem;
}

.schoolListFormContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}
.schoolListContainer h4 {
    margin: 0;
    text-transform: uppercase;
}

.schoolListRow:hover {
    background-color: aliceblue;
    cursor: pointer;
}

.selectSchool h4 {
    margin: 0;
}
.selectSchool a {
    display: inline-block;
    margin-top: 5px;
    color: #617D8B;
    cursor: pointer;
}

#onboardingPhotoLabel {
    border: 1px black solid;
    padding: 9px;
    border-radius: 3px;
    cursor: pointer;
    margin: 5px;
}

#onboardingPhotoLabel:hover {
    background-color: lightgray;
}
.sponsorChildContainer {
    position: relative;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap
}
.paymentContainer {
    position: fixed;
    margin-top: 2%;
    margin-left: 5%;
    width: 34%;
}
.studentCardContainer {
    position: absolute;
    top: 0;
    right: 0;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 60%;
    border: black;
    -webkit-justify-content: center;
            justify-content: center;
}

.studentCard {
    width: 250px;
    margin: 10px;
}

.totalDueLabel {
    display: inline;
    color: black;
}

#modal{
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-content: center;
            align-content: center;
    -webkit-align-items: center;
            align-items: center;
    width: 70%;
    height: 70%;
    margin: auto;   
    border: #617D8B solid 15px;
    border-radius: 4px;

}

#addStudentModalContainer {
    height: 100%;
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-flex-direction: column;
            flex-direction: column;
    background-color: aliceblue;
    padding: 0;
    text-align: center;
    overflow-x: hidden;
}

#addStudentModalContainer h2 {
    margin-top: 5px;
    color: #617D8B;
}
.addStudentModel {
    background-color: aliceblue;
    width: 100%;
}

#form {
    background-color: aliceblue;
}

.addStudentDDMenu {
    width: 200px;
}
/* .editStudentModal {
    display: flex;
    flex-direction: column;
    height: 40%;
}

.editStudentModal  {
    height: 40%;
} */

.labelContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.labelContainer label {
    -webkit-transform: none;
            transform: none;
}

.labelContainer  .labelSelection{ 
    margin: 0;
    text-align: left;
}

#addStudent {
    margin: 10px;
}

#editStudent {
    margin: 10px;
}

.formImgContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-content: center;
            align-content: center;
    -webkit-justify-content: center;
            justify-content: center;
}
.headerBtns {
    width: 225px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-align-items: center;
            align-items: center;
}
.visits-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
}

.visits-container .visits-list {
    width: 30%;
    height: 93vh;
    max-height: 93vh;
    overflow: scroll;
    background: white;
    border-right: solid gray 2px;
}

.visits-container .visits-list .full {
    width: 100%;
}

.visits-container .visits-list .add-visit-btn {
    margin-top: 10px;
    position: relative;
    width: 105px;
    right: 10px;
}

.visits-container .visits-list .visit {
    width: 100%;
}

.visits-container .visits-list .selected {
    background: rgb(49, 91, 105);
}

.visits-container .visits-list .visit .cell {
    width: 50%;
}

.visits-container .visits-list .visit:hover{
    background: lightblue;
}

.visits-container .visit-display {
    width: 70%;
    height: 93vh;
    background: white;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    padding: 0 7%;

}

.visits-container .visit-display .white {
    background: white;
    border: none;
}

.visits-container .visit-display .visit-header .date{
    color: black;
    margin-left: 5%;
}

.visits-container .visit-display .visit-header .school{
    color: black;
    margin-left: 5%;
}

.visits-container .visit-display .visit-body .text {
    white-space: pre-wrap;
    width: 100%;
    text-align: left;
    color: black;
}

.visits-container .visit-display .single-visit-header {
    padding-top: 2%;
}

.visits-container .visit-display .visit-header .school-selector {
    position: relative;
    bottom: 21px;
    width: 250px;
    text-align: left;
    left: 5%;
}

.visits-container .visit-display .visit-header {
    height: 10vh;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: baseline;
            align-items: baseline;
}

.visits-container .visit-display .visit-body {
    height: 69vh;
    max-height: 69vh;
    overflow-y: scroll;
    max-width: 100%;
    overflow-x: hidden;
    padding: 5px;
}

.visits-container .visit-display .visit-footer {
    width: 100%;
    height: 10vh;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
    -webkit-align-items: flex-end;
            align-items: flex-end; 
}

.visits-container .visit-display .visit-footer .edit-btn {
    margin-right: 10px;
}
.adminStudentCount {
    line-height: .5;
    border-radius: 5px;
    text-align: center;
    padding: 0 5px;
    margin-left: 2px;
}


.adminHeaderContainer {
    display: -webkit-flex;
    display: flex;
    position: relative;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    padding-top: 15px
}

.adminHeaderContainer .headerRight {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 20%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
}
.adminHeaderContainer .headerLeft {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    width: 70%;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.visitAdminContainer {
    overflow-y: scroll;
    max-height: 250px;
}

.visitListRow:hover {
    background-color: rgba(233,255,255, .3);
    cursor: pointer;
}

.headerLeft h3 {
    color: #617D8B;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0;
}
.adminStudentCardContainer {
    position: absolute;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap;
        width: 100%;
        border: black;
        -webkit-justify-content: center;
                justify-content: center; 
        overflow: hidden;  
}

.adminStudentBtn {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
}
.adminStudentBtn  .adminStudentCount {
    margin: 5px;
}
.landing-container {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    background: whitesmoke;
}

.landing-container .jumbotron {
    background-image: url(/static/media/school-jumbotron.271f0060.jpg);
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.landing-container .jumbotron .jumbo-inner{
   background: rgba(35, 66, 66, 0.7);
   height:100vh;
   width: 100%;
   z-index: 2;
   display: -webkit-flex;
   display: flex;
}

.landing-container .jumbotron .jumbo-inner-left{
    width: 60%;
    height: 100vh;
}

.landing-container .jumbotron .jumbo-inner-right{
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    -webkit-justify-content: center;
            justify-content: center;
    width: 40%;
    height: 100vh;
}

.landing-container .jumbotron .jumbo-inner-right h1{
margin: 5px 0;;
color: whitesmoke;
font-size: 75px; 
}

.landing-container .jumbotron .jumbo-inner-right .last{
    margin-bottom: 30px;
}

.landing-container .jumbotron .jumbo-inner-right p{
    margin: 5px 0;
    color: whitesmoke;
    font-size: 25px; 
    text-align: left;
}

.landing-container .landing-content {
    -webkit-align-self: center;
            align-self: center;
    margin-bottom: 50px;
}

.landing-container .mission {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.landing-container .mission h3 {
    margin: 0;
    margin-top: 50px;
    padding-bottom: 10px;
    width: 50%;
    text-align: center;
    font-size: 40px;
    border-bottom: solid black 2px;
}

.landing-container .mission p {
    width: 60%;
    text-align: left;
    font-size: 20px;
}

.landing-container .roles {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.landing-container .roles .header {
    margin: 0;
    margin-top: 50px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 40px;
    border-bottom: solid black 2px;
    width: 50%;
}

.landing-container .roles .role-cards {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-around;
            justify-content: space-around;
    margin-top: 50px;
}

.landing-container .roles .role-card {
    width: 27%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.landing-container .role-card h3 {
    margin: 0;
    margin-top: 15px;
    padding-bottom: 7px;
    text-align: center;
    font-size: 25px;
    border-bottom: solid black 2px;
    width: 60%;
}

.landing-container .roles .role-card p {
    text-align: left;
    font-size: 18px;
}

.landing-container .roles .role-cards img {
    height: 100px;
    width: 100px;
}

.landing-container .cta {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.landing-container .cta h3 {
    margin: 0;
    margin-top: 50px;
    text-align: center;
    font-size: 40px;
    border-bottom: solid black 2px;
    width: 50%;
}

.landing-container .cta p {
    font-size: 20px;
    margin-bottom: 15px;
}

.landing-container .about-us {
    width: 100%;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.landing-container .about-us h3 {
    margin: 0;
    margin-top: 50px;
    text-align: center;
    font-size: 40px;
    border-bottom: solid black 2px;
    width: 50%;
}

.landing-container .about-us p {
    font-size: 20px;
    text-align: left;
    width: 60%;
    margin-bottom: 50px;
}

.landing-container .about-us .promo-cards {
    width: 90%;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-evenly;
            justify-content: space-evenly;
}

.landing-container .about-us .promo-card .main {
    height: 150px;
    width: 150px;
    border-radius: 100px;
}

.landing-container .about-us .promo-card {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.landing-container .about-us .promo-card h5 {
    margin: 0;
    margin-top: 15px;
    padding-bottom: 7px;
    text-align: center;
    font-size: 25px;
}

.landing-container .about-us .promo-card .link img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.landing-container .about-us .promo-card .link {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
            align-items: center;
}

.landing-container .about-us .promo-card a {
    color: black;
    text-decoration: none;
    font-size: 20px;
}

.landing-container .about-us .promo-card a:hover {
    color: rgb(29, 64, 131);
}

.landing-container .footer {
    width: 100%;
    height: 30px;
    background: rgba(35, 66, 66, 0.7);
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    margin-top: 15px;
}

@media (max-width: 600px) {
    .landing-container .jumbotron {
        background-image: url(/static/media/school-jumbo-mobile.70cd8805.jpg);
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .landing-container .jumbotron .jumbo-inner-left {
        width: 45%;
    }

    .landing-container .jumbotron .jumbo-inner-right h1 {
        font-size: 40px;
        margin-top: 0;
    }

    .landing-container .jumbotron .jumbo-inner-right .last{
        margin-bottom: 15px;
    }

    .landing-container .jumbotron .jumbo-inner-right p {
        font-size: 18px;
    }    

    .landing-container .landing-content {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;

    }

    .landing-container .roles .role-cards {
        width: 90%;
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
                flex-direction: column;
        -webkit-align-items: center;
                align-items: center;
    }

    .landing-container .roles .role-card {
        width: 80%;
        margin-top: 50px;
    }
    
    .landing-container .cta {
        width: 80%;
    }

    .landing-container .cta h3 {
        width: 100%;
    }

    .landing-container .cta p {
        margin-bottom: 25px;
    }    

    .landing-container .about-us .promo-cards {
        -webkit-flex-direction: column;
                flex-direction: column;
        width: 90%;
        -webkit-align-items: center;
                align-items: center;
    }

    .landing-container .about-us .promo-card {
        margin-bottom: 25px;
    }
  }
.myAccountContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.editUserForm {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.userPhoto {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    
}
* {
  box-sizing: border-box;
}

.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}


@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}


@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

