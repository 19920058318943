.landing-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    background: whitesmoke;
}

.landing-container .jumbotron {
    background-image: url('../imgs/school-jumbotron.jpg');
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.landing-container .jumbotron .jumbo-inner{
   background: rgba(35, 66, 66, 0.7);
   height:100vh;
   width: 100%;
   z-index: 2;
   display: flex;
}

.landing-container .jumbotron .jumbo-inner-left{
    width: 60%;
    height: 100vh;
}

.landing-container .jumbotron .jumbo-inner-right{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    width: 40%;
    height: 100vh;
}

.landing-container .jumbotron .jumbo-inner-right h1{
margin: 5px 0;;
color: whitesmoke;
font-size: 75px; 
}

.landing-container .jumbotron .jumbo-inner-right .last{
    margin-bottom: 30px;
}

.landing-container .jumbotron .jumbo-inner-right p{
    margin: 5px 0;
    color: whitesmoke;
    font-size: 25px; 
    text-align: left;
}

.landing-container .landing-content {
    align-self: center;
    margin-bottom: 50px;
}

.landing-container .mission {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-container .mission h3 {
    margin: 0;
    margin-top: 50px;
    padding-bottom: 10px;
    width: 50%;
    text-align: center;
    font-size: 40px;
    border-bottom: solid black 2px;
}

.landing-container .mission p {
    width: 60%;
    text-align: left;
    font-size: 20px;
}

.landing-container .roles {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-container .roles .header {
    margin: 0;
    margin-top: 50px;
    padding-bottom: 10px;
    text-align: center;
    font-size: 40px;
    border-bottom: solid black 2px;
    width: 50%;
}

.landing-container .roles .role-cards {
    width: 90%;
    display: flex;
    justify-content: space-around;
    margin-top: 50px;
}

.landing-container .roles .role-card {
    width: 27%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-container .role-card h3 {
    margin: 0;
    margin-top: 15px;
    padding-bottom: 7px;
    text-align: center;
    font-size: 25px;
    border-bottom: solid black 2px;
    width: 60%;
}

.landing-container .roles .role-card p {
    text-align: left;
    font-size: 18px;
}

.landing-container .roles .role-cards img {
    height: 100px;
    width: 100px;
}

.landing-container .cta {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-container .cta h3 {
    margin: 0;
    margin-top: 50px;
    text-align: center;
    font-size: 40px;
    border-bottom: solid black 2px;
    width: 50%;
}

.landing-container .cta p {
    font-size: 20px;
    margin-bottom: 15px;
}

.landing-container .about-us {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.landing-container .about-us h3 {
    margin: 0;
    margin-top: 50px;
    text-align: center;
    font-size: 40px;
    border-bottom: solid black 2px;
    width: 50%;
}

.landing-container .about-us p {
    font-size: 20px;
    text-align: left;
    width: 60%;
    margin-bottom: 50px;
}

.landing-container .about-us .promo-cards {
    width: 90%;
    display: flex;
    justify-content: space-evenly;
}

.landing-container .about-us .promo-card .main {
    height: 150px;
    width: 150px;
    border-radius: 100px;
}

.landing-container .about-us .promo-card {
    display: flex;
    flex-direction: column;
}

.landing-container .about-us .promo-card h5 {
    margin: 0;
    margin-top: 15px;
    padding-bottom: 7px;
    text-align: center;
    font-size: 25px;
}

.landing-container .about-us .promo-card .link img {
    width: 20px;
    height: 20px;
    margin-right: 5px;
}

.landing-container .about-us .promo-card .link {
    display: flex;
    align-items: center;
}

.landing-container .about-us .promo-card a {
    color: black;
    text-decoration: none;
    font-size: 20px;
}

.landing-container .about-us .promo-card a:hover {
    color: rgb(29, 64, 131);
}

.landing-container .footer {
    width: 100%;
    height: 30px;
    background: rgba(35, 66, 66, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 15px;
}

@media (max-width: 600px) {
    .landing-container .jumbotron {
        background-image: url('../imgs/school-jumbo-mobile.jpg');
        height: 100vh;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }

    .landing-container .jumbotron .jumbo-inner-left {
        width: 45%;
    }

    .landing-container .jumbotron .jumbo-inner-right h1 {
        font-size: 40px;
        margin-top: 0;
    }

    .landing-container .jumbotron .jumbo-inner-right .last{
        margin-bottom: 15px;
    }

    .landing-container .jumbotron .jumbo-inner-right p {
        font-size: 18px;
    }    

    .landing-container .landing-content {
        display: flex;
        flex-direction: column;

    }

    .landing-container .roles .role-cards {
        width: 90%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .landing-container .roles .role-card {
        width: 80%;
        margin-top: 50px;
    }
    
    .landing-container .cta {
        width: 80%;
    }

    .landing-container .cta h3 {
        width: 100%;
    }

    .landing-container .cta p {
        margin-bottom: 25px;
    }    

    .landing-container .about-us .promo-cards {
        flex-direction: column;
        width: 90%;
        align-items: center;
    }

    .landing-container .about-us .promo-card {
        margin-bottom: 25px;
    }
  }