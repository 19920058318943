.visits-container {
    width: 100%;
    display: flex;
}

.visits-container .visits-list {
    width: 30%;
    height: 93vh;
    max-height: 93vh;
    overflow: scroll;
    background: white;
    border-right: solid gray 2px;
}

.visits-container .visits-list .full {
    width: 100%;
}

.visits-container .visits-list .add-visit-btn {
    margin-top: 10px;
    position: relative;
    width: 105px;
    right: 10px;
}

.visits-container .visits-list .visit {
    width: 100%;
}

.visits-container .visits-list .selected {
    background: rgb(49, 91, 105);
}

.visits-container .visits-list .visit .cell {
    width: 50%;
}

.visits-container .visits-list .visit:hover{
    background: lightblue;
}

.visits-container .visit-display {
    width: 70%;
    height: 93vh;
    background: white;
    display: flex;
    flex-direction: column;
    padding: 0 7%;

}

.visits-container .visit-display .white {
    background: white;
    border: none;
}

.visits-container .visit-display .visit-header .date{
    color: black;
    margin-left: 5%;
}

.visits-container .visit-display .visit-header .school{
    color: black;
    margin-left: 5%;
}

.visits-container .visit-display .visit-body .text {
    white-space: pre-wrap;
    width: 100%;
    text-align: left;
    color: black;
}

.visits-container .visit-display .single-visit-header {
    padding-top: 2%;
}

.visits-container .visit-display .visit-header .school-selector {
    position: relative;
    bottom: 21px;
    width: 250px;
    text-align: left;
    left: 5%;
}

.visits-container .visit-display .visit-header {
    height: 10vh;
    display: flex;
    align-items: baseline;
}

.visits-container .visit-display .visit-body {
    height: 69vh;
    max-height: 69vh;
    overflow-y: scroll;
    max-width: 100%;
    overflow-x: hidden;
    padding: 5px;
}

.visits-container .visit-display .visit-footer {
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: flex-end;
    align-items: flex-end; 
}

.visits-container .visit-display .visit-footer .edit-btn {
    margin-right: 10px;
}