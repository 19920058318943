.adminStudentCount {
    line-height: .5;
    border-radius: 5px;
    text-align: center;
    padding: 0 5px;
    margin-left: 2px;
}


.adminHeaderContainer {
    display: flex;
    position: relative;
    justify-content: space-between;
    flex-wrap: wrap;
    padding-top: 15px
}

.adminHeaderContainer .headerRight {
    display: flex;
    flex-wrap: wrap;
    width: 20%;
    align-items: center;
    justify-content: space-evenly;
    flex-direction: column-reverse;
}
.adminHeaderContainer .headerLeft {
    display: flex;
    flex-wrap: wrap;
    width: 70%;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
}

.visitAdminContainer {
    overflow-y: scroll;
    max-height: 250px;
}

.visitListRow:hover {
    background-color: rgba(233,255,255, .3);
    cursor: pointer;
}

.headerLeft h3 {
    color: #617D8B;
    text-transform: uppercase;
    font-weight: 500;
    margin-bottom: 0;
}
.adminStudentCardContainer {
    position: absolute;
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        border: black;
        justify-content: center; 
        overflow: hidden;  
}

.adminStudentBtn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.adminStudentBtn  .adminStudentCount {
    margin: 5px;
}